// Logout.js

import React, { useEffect } from "react"; // React에서 useEffect 가져오기
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // React Router에서 useNavigate 가져오기

// 로그아웃 함수를 내보내기
export const logoutUser = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
    console.info("로그아웃 성공");
  } catch (error) {
    console.error("로그아웃 중 오류 발생:", error);
  }
};

// Logout 컴포넌트
const Logout = ({ setUserStatus }) => {
  const navigate = useNavigate(); // useNavigate 훅 사용

  useEffect(() => {
    const handleLogout = async () => {
      await logoutUser();
      setUserStatus(false);
      navigate("/");
    };

    handleLogout();
  }, [navigate, setUserStatus]);

  return (
    <div className="logout-container">
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;
