import React from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from './firebase'; // Make sure to correctly import your Firestore db instance



const About = () => {

    const deleteAllMessages = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "messages"));
            querySnapshot.forEach(async (document) => {
                await deleteDoc(doc(db, "messages", document.id));
            });
            alert("All messages have been deleted successfully!");
        } catch (error) {
            console.error("Error deleting messages: ", error);
        }
    };

    const clearLocalStorage = () => {
        localStorage.clear();
        console.log("LocalStorage cleared");
    };

    return (
        <div>
            <h1>About 화면입니다.</h1>
            <div className="admin-deleteMsg">
                <button onClick={deleteAllMessages}>Delete All Messages</button> 
            </div>
            <div className="admin-clearLocal">
                <button onClick={() => clearLocalStorage()}>Clear local storage</button>
            </div>
        </div>
    );
};

export default About;
