// ChatRoom.js

import React, { useState, useEffect, useRef  } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "./firebase"; // Import Firestore
import { collection, query, where, getDoc, getDocs, addDoc, updateDoc, serverTimestamp, onSnapshot  } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Import Firebase Storage
import { doc, deleteDoc } from "firebase/firestore"; // Import deleteDoc
import './ChatRoom.css';

function ChatRoom() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [contextMenu, setContextMenu] = useState({ show: false, x: 0, y: 0, message: null }); // Context menu state
  const messagesEndRef = useRef(null); // Reference for chat messages end
  const fileInputRef = useRef(null); // Define a reference for the file input

  useEffect(() => {
    if (!currentUser) return; // Ensure currentUser is defined
    
    const storedMessages = JSON.parse(localStorage.getItem('messages') || '[]');
    const lastFetchTime = localStorage.getItem('lastFetchTime');

    console.log("storedMessages.length = ", storedMessages.length);
    console.log("lastFetchTime", lastFetchTime);

    if (storedMessages.length >= 0) {
      // If there are stored messages, fetch only new ones after the last fetch time 
  
      const messagesCollection = collection(db, "messages");
      // const q = query(messagesCollection, where("timestamp", ">", new Date(lastFetchTime)));
      let q;
      if (lastFetchTime) {
        q = query(messagesCollection, where("timestamp", ">", new Date(lastFetchTime)));
      } else {
        q = messagesCollection; // Fetch all messages if lastFetchTime is null
      }

      
      // Listen for real-time updates
      const unsubscribe = onSnapshot(q, (messagesSnapshot) => {
        console.log("messagesSnapshot1: ", messagesSnapshot);
        
        const messagesList0 = messagesSnapshot.docs.map((tdoc) => {
          const data = tdoc.data(); 

          console.log("tdoc: ", tdoc);

          console.log("data: ", data);
          
          if (data.user !== currentUser.email && data.readBySomeone === 0) {
            // Update readBySomeone to 1 for messages not written by currentUser  
            const messageRef = doc(db, "messages", tdoc.id);
            const newReadBySomeone = data.readBySomeone + 1;
            updateDoc(messageRef, { readBySomeone: newReadBySomeone });
          }  

          if (!data.blockedBy || !data.blockedBy.includes(currentUser.email)) { 
            console.log('There is a message to show.');
            return {
              ...data,
              timestamp: data.timestamp && data.timestamp.toDate ? data.timestamp : '',
            };
          }
          return null;
        });
      
        const messagesList1 = messagesList0.filter((message) => message !== null);

        console.log("messagesList1: ", messagesList1);
        console.log(`messagesList1.length: ${messagesList1.length}`);
      
        messagesList1.sort((a, b) => a.timestamp - b.timestamp);
    
        const messagesList = messagesList1.map((message) => ({
          ...message,
          timestamp: message.timestamp ? message.timestamp.toDate().toLocaleTimeString() : '', // Convert to local time string
        }));
    
        const updatedMessages = [...storedMessages, ...messagesList];
        
        // Save the updated messages and new last fetch time to localStorage
        localStorage.setItem('messages', JSON.stringify(updatedMessages));
        localStorage.setItem('lastFetchTime', new Date().toISOString());

        setMessages(updatedMessages);
      });

      return () => unsubscribe();
    }
    else {
      // No stored messages, perform the initial fetch
      async function fetchMessages() {
        const messagesCollection = collection(db, "messages"); 
        const messagesSnapshot = await getDocs(messagesCollection);

        console.log("messagesSnapshot", messagesSnapshot);

        const messagesList0 = messagesSnapshot.docs.map((tdoc) => {
          const data = tdoc.data();

          if (data.user !== currentUser.email && data.readBySomeone === 0) {
            const messageRef = doc(db, "messages", tdoc.id);
            const newReadBySomeone = data.readBySomeone + 1;
            updateDoc(messageRef, { readBySomeone: newReadBySomeone });
          }

          if (!data.blockedBy || !data.blockedBy.includes(currentUser.email)) {
            return {
              ...data,
              timestamp: data.timestamp && data.timestamp.toDate ? data.timestamp : '',
            };
          }
          return null;
        }); 

        const messagesList1 = messagesList0.filter((message) => message !== null);
        if (messagesList1.length > 1) {
          messagesList1.sort((a, b) => a.timestamp - b.timestamp);
        }

        const messagesList = messagesList1.map((message) => ({
          ...message,
          timestamp: message.timestamp ? message.timestamp.toDate().toLocaleTimeString() : '',
        }));

        localStorage.setItem('messages', JSON.stringify(messagesList));
        localStorage.setItem('lastFetchTime', new Date().toISOString());

        setMessages(messagesList);
      }

      fetchMessages();

    }

  }, [currentUser]);

    // Scroll to bottom function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" }); // Use "auto" for immediate scrolling
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages change
  }, [messages]);

  useEffect(() => {
    const auth = getAuth();

    console.log("before onAuthStateChanged ");
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("after onAuthStateChanged - user: ", user);
      if (user) {
        setIsAuthenticated(true);
        setCurrentUser(user);
        console.log(`isAuthenticated: true`);
        console.log(`currentUser: ${user.email}`);
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
        console.log(`isAuthenticated: false`);
        console.log(`currentUser: No user`);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const handleSendMessage = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (newMessage.trim() === "") return;
  
      const messageData = {
        id: messages.length + 1, 
        user: currentUser.email,
        user_displayName: currentUser.displayName,
        content: newMessage,
        timestamp: serverTimestamp(),
        blockedBy: [],
        readBySomeone: 0,
      };
  
      await addDoc(collection(db, "messages"), { ...messageData, timestamp: serverTimestamp() });

      
      // fetchAndUpdateMessages();

      scrollToBottom(); // Scroll to bottom after fetching messages
      setNewMessage("");
    }
  };

  const getDocumentIDByMessageId = async (messageId) => {
    const messagesCollection = collection(db, "messages");
    const q = query(messagesCollection, where("id", "==", messageId));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const docId = querySnapshot.docs[0].id; // 첫 번째 문서의 ID를 가져옴
      console.log(`Document ID: ${docId}`);
      return docId;
    } else {
      console.log("No document found with the specified messageId");
      return null;
    }
  };

  // Right-click handler for context menu
  const handleRightClick = (event, message) => {
    // Check if the device is not a touch device (desktop or laptop)
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (!isTouchDevice) {
      event.preventDefault();
    }
    console.log(`message.user : ${message.user}`);
    console.log(`message.id : ${message.id}`);
    setContextMenu({ show: true, x: event.clientX, y: event.clientY, message });
  };

  const handleMenuAction = async (action, message) => {
    // setContextMenu({ ...contextMenu, show: false });

    // const auth = getAuth();
    // const currentUser = auth.currentUser;

    console.log("I am in handleMenuAction()");

    console.log("currentUser: ", currentUser);
    
    if (action === "reply") {
      // setNewMessage(`@${contextMenu.message.user} `); // Example for reply
      setNewMessage(`@${message.user} `); // Example for reply
    } else if (action === "delete") {
      try {
        const messageId = message.id;

        // Convert the message ID to a string and ensure it's valid
        if (typeof messageId !== "number" || messageId === null) {
          throw new Error("Invalid message ID");
        }

        // Retrieve the correct document reference using Firestore
        // const messagesCollection = collection(db, "messages");
        // const messageRef = doc(messagesCollection, messageId.toString()); // Convert number ID to string 

        const documentId = await getDocumentIDByMessageId(messageId);
        const documentRef = doc(db, "messages", documentId); 

        console.log(`documentId: ${documentId}`);
        console.log(`documentRef id: ${documentRef.id}`);
        console.log(`documentRef Path: ${documentRef.path}`);
        
        const messageSnapshot = await getDoc(documentRef);

        // Delete the document
        if (messageSnapshot.exists()) {

          console.log(`messageSnapshot.data: ${messageSnapshot.data()}`);

          const messageData = messageSnapshot.data(); 

          console.log(`messageData.readBySomeone: ${messageData.readBySomeone}`);
          console.log("messageData.user: ", messageData.user);
          console.log("currentUser.email: ", currentUser.email);

          if (messageData.readBySomeone === 0 && messageData.user === currentUser.email){ 
            await deleteDoc(documentRef);
            console.log("Deleted successfully");

            // Verify deletion
            const deletedMessage = await getDoc(documentRef);
            if (!deletedMessage.exists()) {
              console.log("Message deleted from Firestore.");

              // Remove the message from local storage
              const storedMessages = JSON.parse(localStorage.getItem('messages') || '[]');
              const updatedMessages = storedMessages.filter((msg) => msg.id !== messageId);
              localStorage.setItem('messages', JSON.stringify(updatedMessages));
              console.log("Message deleted from localStorage.");
            } else {
              console.log("Failed to delete the message from Firestore.");
            }
          } 
          else {
 
            // Add current user ID to the blockedBy list if readBySomeone is 1
            console.log("blockedBy list: ", messageData.blockedBy);
            console.log(`currentUser.email: ${currentUser.email}`);
            if (messageData.blockedBy.includes(currentUser.email)){
              console.log("User is already in the blockedBy list");
            }
            else {  
              // Update Firestore
              const updatedBlockedBy = [...messageData.blockedBy, currentUser.email];
              await updateDoc(documentRef, { blockedBy: updatedBlockedBy });
              console.log("User added to blockedBy list successfully!");

              // Update localStorage
              const storedMessages = JSON.parse(localStorage.getItem('messages') || '[]');
              const updatedMessages = storedMessages.map((msg) => {
                if (msg.id === messageId) {
                  return {
                    ...msg,
                    blockedBy: [...msg.blockedBy, currentUser.email] // Update blockedBy in the message
                  };
                }
                return msg;
              });
              localStorage.setItem('messages', JSON.stringify(updatedMessages));
              console.log("BlockedBy updated in localStorage."); 
            } 
          }

        } else {
          console.error("Message not found.");
        } 

        // Update the local state after deletion
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
      } catch (error) {
        console.error("Failed to delete message:", error);
      }
    } else if (action === "copy") {
      // navigator.clipboard.writeText(message.content);

      // Check if the Clipboard API is supported
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API
        navigator.clipboard.writeText(message.content).then(() => {
          alert("Text copied to clipboard!");
        }).catch((err) => {
          console.error("Failed to copy text: ", err);
        });
      } else {
        // Fallback for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = message.content;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
          alert("Text copied to clipboard!");
        } catch (err) {
          console.error("Failed to copy text: ", err);
        }

        document.body.removeChild(textArea);
      }
    }
  };


  const handlePopupMenuAction = async (action) => {
    setContextMenu({ ...contextMenu, show: false });

    console.log("I am in handlePopupMenuAction()");
    
    if (action === "reply") {
      setNewMessage(`@${contextMenu.message.user} `); // Example for reply
    } else if (action === "delete") {
      try {
        const messageId = contextMenu.message.id; 

        // Convert the message ID to a string and ensure it's valid
        if (typeof messageId !== "number" || messageId === null) {
          throw new Error("Invalid message ID");
        }

        // Retrieve the correct document reference using Firestore
        // const messagesCollection = collection(db, "messages");
        // const messageRef = doc(messagesCollection, messageId.toString()); // Convert number ID to string 

        const documentId = await getDocumentIDByMessageId(messageId);
        const documentRef = doc(db, "messages", documentId); 

        console.log(`documentId: ${documentId}`);
        console.log(`documentRef id: ${documentRef.id}`);
        console.log(`documentRef Path: ${documentRef.path}`);
        
        const messageSnapshot = await getDoc(documentRef);

        // Delete the document
        if (messageSnapshot.exists()) {

          console.log(`messageSnapshot.data: ${messageSnapshot.data()}`);

          const messageData = messageSnapshot.data(); 
 
          console.log(`messageData.readBySomeone: ${messageData.readBySomeone}`);
          console.log("messageData.user: ", messageData.user);
          console.log("currentUser.email: ", currentUser.email);

          if (messageData.readBySomeone === 0 && messageData.user === currentUser.email){ 
            await deleteDoc(documentRef);
            // console.log("Deleted successfully");

            // Verify deletion
            const deletedMessage = await getDoc(documentRef);
            if (!deletedMessage.exists()) {
              console.log("Message deleted from Firestore.");

              // Remove the message from local storage
              const storedMessages = JSON.parse(localStorage.getItem('messages') || '[]');
              const updatedMessages = storedMessages.filter((msg) => msg.id !== messageId);
              localStorage.setItem('messages', JSON.stringify(updatedMessages));
              console.log("Message deleted from localStorage.");
            } else {
              console.log("Failed to delete the message from Firestore.");
            }
          } 
          
           
          // Add current user ID to the blockedBy list if readBySomeone is 1
          console.log("blockedBy list: ", messageData.blockedBy);
          console.log(`currentUser.email: ${currentUser.email}`);
          if (messageData.blockedBy.includes(currentUser.email)){
            console.log("User is already in the blockedBy list");
          }
          else { 
            // Update Firestore
            const updatedBlockedBy = [...messageData.blockedBy, currentUser.email];
            await updateDoc(documentRef, { blockedBy: updatedBlockedBy });
            console.log("User added to blockedBy list successfully!");

            // Update localStorage
            const storedMessages = JSON.parse(localStorage.getItem('messages') || '[]');
            const updatedMessages = storedMessages.map((msg) => {
              if (msg.id === messageId) {
                return {
                  ...msg,
                  blockedBy: [...msg.blockedBy, currentUser.email] // Update blockedBy in the message
                };
              }
              return msg;
            });
            localStorage.setItem('messages', JSON.stringify(updatedMessages));
            console.log("BlockedBy updated in localStorage.");
          } 
        } else {
          console.error("Message not found.");
        } 

        // Update the local state after deletion
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
      } catch (error) {
        console.error("Failed to delete message:", error);
      }
    } else if (action === "copy") {
      navigator.clipboard.writeText(contextMenu.message.content);
    }
  };
  
  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.show) {
        setContextMenu({ ...contextMenu, show: false });
      }
    };
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);
  

  // Function to render message content with clickable URLs
  const renderMessageContent = (content, fileURL, fileType) => {
    if (fileURL) {
      // console.log("content", content);
      // console.log("fileURL", fileURL);
      // console.log("fileType", fileType);
      // Display file preview for uploaded files
      if (fileType.startsWith("image/")) {
        return <img src={fileURL} alt={content} style={{ width: "100px", height: "100px" }} />;
      } else if (fileType === "audio/mpeg") {
        return <audio controls src={fileURL} style={{ width: '300px', height: '30px' }}>Your browser does not support the audio element.</audio>;
      } else if (fileType === "application/pdf") {
        return <a href={fileURL} target="_blank" rel="noopener noreferrer"><img src="pdf_icon.png" alt="PDF" /></a>;
      } else if (fileType === "application/vnd.ms-powerpoint") {
        return <a href={fileURL} target="_blank" rel="noopener noreferrer"><img src="ppt_icon.png" alt="PPT" /></a>;
      } else if (fileType === "application/x-hwp") {
        return <a href={fileURL} target="_blank" rel="noopener noreferrer"><img src="hwp_icon.png" alt="HWP" /></a>;
      } else {
        return <a href={fileURL} target="_blank" rel="noopener noreferrer">{content}</a>;
      }
    } else {
      // Handle regular URLs
      const urlRegex = /https?:\/\/[^\s]+/g;
      const parts = content.split(urlRegex);
      const urls = content.match(urlRegex);
      const result = [];

      console.log("Message text is displayed here in parts");
  
      parts.forEach((part, i) => {
        result.push(part);
        if (urls && urls[i]) {
          const url = urls[i];
          if (url.includes("youtube.com") || url.includes("youtu.be")) {
            const videoId = url.split("v=")[1]?.split("&")[0] || url.split("/").pop();
            result.push(
              <iframe
                key={i}
                width="300"
                height="200"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            );
          } else {
            result.push(
              <a key={i} href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            );
          }
        }
      });
  
      return result;
    }
  };
  
  
  
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE_MB) * 1024 * 1024;
    if (file.size > maxFileSize) {
      alert(`File size should not exceed ${process.env.REACT_APP_MAX_FILE_SIZE_MB} MB.`);
      return;
    }
  
    const storage = getStorage();
    const fileRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);
  
    uploadTask.on('state_changed', null, (error) => {
      console.error("Upload failed:", error);
      alert("File upload failed. Please try again.");
    }, async () => {
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
  
      const messageData = {
        id: messages.length + 1,
        user: currentUser.email,
        user_displayName: currentUser.displayName,
        content: file.name,
        timestamp: serverTimestamp(),
        fileURL: downloadURL,
        fileType: file.type,
        blockedBy: [], // Initialize blockedBy as an empty array
        readBySomeone: 0, // Default value set to 0
      };
  
      await addDoc(collection(db, "messages"), { ...messageData, timestamp: serverTimestamp() });
  
      setMessages((prevMessages) => [...prevMessages, { ...messageData, timestamp: new Date().toLocaleTimeString() }]);
    });
    scrollToBottom(); // Scroll to bottom after fetching messages

    setNewMessage("");
  };
  
  const touchTimeoutRef = useRef(null);

  const handleTouchStart = (e, message) => {
    e.persist(); // Preserve synthetic event
    touchTimeoutRef.current = setTimeout(() => {
      const touch = e.touches[0]; // Get the first touch event
      const x = touch.clientX - 100;
      const y = touch.clientY;
      handleRightClick({ clientX: x, clientY: y }, message); // Pass the coordinates to handleRightClick
    }, 500);
  };
  
  const handleTouchEnd = () => {
    if (touchTimeoutRef.current) {
      clearTimeout(touchTimeoutRef.current); // Clear timeout if touch ends early
    }
  };

  return (
    <div className="chatroom-container">
      {isAuthenticated ? (
        <div className="chatroom-inner">
          <h2 className="chatroom-title">Chat Room</h2>
          <div className="chat-messages">
            {messages.filter((message) => !message.blockedBy.includes(currentUser.email)).map((message, index) => (
              <div
                key={`message-${message.id}-${index}`} // Ensure each key is unique
                className={`chat-bubble ${
                  message.user === currentUser.email
                    ? "my-message"
                    : "other-message"
                }`}
                // style={{ backgroundColor: message.readBySomeone === 1 ? "yellow" : "white" }} // Apply pink background
                onContextMenu={(e) => handleRightClick(e, message)} // Attach right-click handler
                onTouchStart={(e) => handleTouchStart(e, message)}
                onTouchEnd={handleTouchEnd}
              >
                <div className="chat-header">
                  <div className="chat-user">{message.user}</div>
                  <div className="chat-read">{message.readBySomeone === 0 ? "0" : message.readBySomeone} </div>
                </div>
                <div className="chat-content">
                  {renderMessageContent(message.content, message.fileURL, message.fileType)}
                </div>
                <div className="chat-footer">
                  <div className="chat-actions">
                    <button onClick={() => handleMenuAction("reply", message)}>Reply</button>
                    <button onClick={() => handleMenuAction("copy", message)}>Copy</button>
                    <button onClick={() => handleMenuAction("delete", message)}>Delete</button>
                  </div>
                  <div className="chat-timestamp">{message.timestamp}</div>
                </div>
              </div>
            ))}
            {/* Dummy div to scroll to */}
            <div ref={messagesEndRef} />
          </div>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleSendMessage}
            placeholder="Type your message..."
            className="chat-input"
          />
          <div>
            <input
              type="file"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <img
              src="/fileupload_icon.png" // Replace with your pin image file path
              alt="Upload"
              onClick={() => fileInputRef.current.click()}
              style={{ cursor: "pointer", width: "30px", height: "30px" }} // Style the image as needed
            />
            
          </div>
          
        </div>
      ) : (
        <h1>Please log in to access the chat room.</h1>
      )}
      {contextMenu.show && (
        <ul className="context-menu" style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}>
          <li onClick={() => handlePopupMenuAction("reply")}>Reply</li>
          <li onClick={() => handlePopupMenuAction("copy")}>Copy</li>
          <li onClick={() => handlePopupMenuAction("delete")}>Delete</li>
        </ul>
      )}
    </div>
  );
}

export default ChatRoom;
