import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import { useNavigate } from "react-router-dom";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "./firebase";
import butterflyImage from './butterfly.png'; 
import './Home.css';

function Home({ setUserStatus }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setCurrentUser(user);
        console.log(`isAuthenticated: true`);
        console.log(`currentUser: ${user.email}`);
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
        console.log(`isAuthenticated: false`);
        console.log(`currentUser: No user`);
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  // const [sessionId, setSessionId] = useState(() => localStorage.getItem("sessionId")); // 초기 로드 시 로컬 스토리지에서 세션 ID 가져오기
  const navigate = useNavigate();
  // const auth = getAuth();

  // const provider = useMemo(() => new GoogleAuthProvider(), []);

  // const handleForceLogout = useCallback(async (user) => {
  //   const newSessionId = Math.random().toString(36).substring(2); // 새로운 세션 ID 생성
  //   setSessionId(newSessionId); // 세션 ID 상태 업데이트
  //   localStorage.setItem("sessionId", newSessionId); // 로컬 스토리지에 세션 ID 저장

  //   const userRef = doc(db, "users", user.uid);
  //   await setDoc(userRef, {
  //     currentSession: newSessionId,
  //     sessionTimestamp: new Date(),
  //   }, { merge: true });

  //   await signOut(auth);
  // }, [auth]);

  // const handleLogin = useCallback(async (email) => {
  //   try {
  //     const userRef = doc(db, "users", email); // Firestore에서 이메일로 사용자의 데이터 참조
  //     const userSnap = await getDoc(userRef);

  //     if (userSnap.exists()) {
  //       const { currentSession } = userSnap.data();

  //       if (currentSession) {
  //         const confirmLogout = window.confirm("기존 세션이 있습니다. 이 세션을 끊고 로그인을 진행할까요?");
  //         if (!confirmLogout) {
  //           return; // 사용자가 취소하면 함수 종료
  //         }
  //       }
  //     }

  //     await signInWithRedirect(auth, provider);

  //   } catch (error) {
  //     console.error("로그인 오류:", error);
  //     alert("로그인에 실패했습니다. 다시 시도해주세요.");
  //   }
  // }, [auth, provider]); 

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       const userRef = doc(db, "users", user.uid);
  //       const userSnap = await getDoc(userRef);

  //       if (userSnap.exists()) {
  //         const { currentSession } = userSnap.data();

  //         console.log(`currentSession Home 1: ${currentSession}`);
  //         console.log(`sessionId Home 1: ${sessionId}`);

  //         if (currentSession && currentSession !== sessionId) { 
  //           const confirmLogout = window.confirm("다른 디바이스를 강제로 로그아웃시킬까요?");
  //           if (confirmLogout) {
  //             await handleForceLogout(user);
  //             await handleLogin(user.email); 
  //           } else {
  //             setIsAuthenticated(true);
  //             setCurrentUser(user);
  //             navigate('/');
  //           }
  //         } else {
  //           setIsAuthenticated(true);
  //           setCurrentUser(user);
  //           setSessionId(currentSession); 
  //           localStorage.setItem("sessionId", currentSession); // 로컬 스토리지에 세션 ID 저장
  //           setUserStatus(`Logged in as ${user.displayName || user.email}`);
  //         }
  //       }
  //     } else {
  //       setIsAuthenticated(false);
  //       setCurrentUser(null);
  //       setUserStatus(null); 
  //       localStorage.removeItem("sessionId"); // 로그아웃 시 로컬 스토리지에서 세션 ID 삭제
  //     }
  //   });

  //   console.log(`currentUser Home : ${currentUser}`);
  //   console.log(`sessionId Home: ${sessionId}`);

  //   return () => unsubscribe();
  // }, [auth, navigate, setUserStatus, handleForceLogout, handleLogin, sessionId, currentUser]);

  const handleStart = () => {
    console.log(`isAuthenticated: ${isAuthenticated}`); 
    console.log(`currentUser: ${currentUser ? currentUser.email : 'No user'}`); 
  
    if (isAuthenticated && currentUser) {
      navigate('/chatroom'); 
    } else {
      navigate('/login'); 
    }
  };

  // 1. Create a style element
  const style = document.createElement('style');
  document.head.appendChild(style);

  // 2. Generate keyframes dynamically with a loop
  let keyframes = '@keyframes flap {\n'; 

  let prevX = 0;
  let prevY = 0;
  
  for (let i = 0; i <= 1000; i += 1) {
    let randomX = Math.random(); // Declare randomX outside of loop
    let randomY = Math.random(); // Declare randomY outside of loop

    // Calculate the scale value for each step
    const scaleValue = (i % 2 === 0) ? 1 : 0.5; // Set scale to 1 if i is even, 0.5 if i is odd
  
    // Recalculate random values for position every 20 steps
    if (i % 20 === 0) {
      randomX = Math.random(); // Update random value for X
      randomY = Math.random(); // Update random value for Y
    }
  
    // Calculate positionX and positionY for each step
    const positionX = 0.2 * randomX + prevX; // Update positionX
    const positionY = 0.2 * randomY + prevY; // Update positionY
  
    // Calculate angle for each step
    const angle = Math.atan2((positionY - prevY), (positionX - prevX)) * (180 / Math.PI); // Convert from radians to degrees
  
    // Update previous positions
    prevX = positionX;
    prevY = positionY;
  
    // Generate keyframe step
    keyframes += `  ${i / 10}% { transform: scale(${scaleValue}) translate(${positionX}px, ${positionY}px) rotate(${angle}deg); }\n`;
  }
  
  keyframes += '}';
  
  // console.log(keyframes);
  

  // 3. Append the keyframes to the style element
  style.textContent += keyframes;

  const backgroundClass = isAuthenticated ? 'authenticated-background' : 'unauthenticated-background';

  return (
    <div className={`home-container ${backgroundClass}`} onClick={handleStart}>  
      <img src={butterflyImage} className="butterfly" alt="Butterfly" />
      <h1> Click anywhere to Start</h1>

      {!isAuthenticated && <p>You are not authenticated yet</p>} 

    </div>
  );
}

export default Home;
