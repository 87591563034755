// 이 코드는 기본적으로 Create React App(CRA)에서 제공하는 Service Worker 등록 코드입니다.

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1]은 localhost의 IPv6 주소입니다.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8은 localhost의 IPv4 주소입니다.
    window.location.hostname.match(
      /^127(?:\.[0-9]+){0,2}\.[0-9]+$/
    )
  );
  
  export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      // 브라우저가 Service Worker를 지원하면 등록을 시도합니다.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // PUBLIC_URL이 다른 출처에 있을 경우 Service Worker를 등록하지 않습니다.
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          // 로컬 환경에서는 서비스 워커를 확인합니다.
          checkValidServiceWorker(swUrl, config);
  
          // 또한, 로컬에서 실행 중일 때 등록을 로그합니다.
          navigator.serviceWorker.ready.then(() => {
            console.log(
              '이 앱은 서비스 워커를 사용하여 로컬 환경에서 캐싱된 페이지를 제공하고 있습니다.'
            );
          });
        } else {
          // 로컬 환경이 아닌 경우 서비스 워커를 등록합니다.
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log(
                  '새 콘텐츠가 사용 가능합니다. 페이지를 새로고침하세요.'
                );
  
                // 호출자가 존재할 경우 콜백 실행
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                console.log('콘텐츠가 오프라인에서 사용 가능합니다.');
  
                // 호출자가 존재할 경우 콜백 실행
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('서비스 워커 등록 중 오류 발생:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    // 서비스 워커를 확인하고, 발견되지 않으면 페이지를 새로고침합니다.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then((response) => {
        // 서비스 워커가 존재하고 JS 파일이면 등록합니다.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // 존재하지 않는 경우 기존 서비스 워커를 제거하고 페이지를 새로고침합니다.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // 서비스 워커를 등록합니다.
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log(
          '인터넷 연결이 없습니다. 오프라인 모드에서 앱이 작동 중입니다.'
        );
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error('서비스 워커 해제 중 오류 발생:', error);
        });
    }
  }
  