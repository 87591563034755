// SignUp.js

import React, { useState } from "react";
import { auth, googleProvider } from "./firebase";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import './SignUp.css';

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");  
  const navigate = useNavigate();

  const handleSignUp = async (event) => {
    event.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    } 

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError("이미 회원이십니다."); // 계정이 이미 존재하는 경우 처리
      } else {
        setError("회원가입에 실패했습니다. 다시 시도해주세요.");
      }
      console.error("회원가입 오류:", error);
    }
  };

  // Google 계정으로 회원가입 처리
  const handleGoogleSignUp = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/'); // 로그인 성공 시 홈으로 리디렉션
    } catch (error) {
      setError("Google 계정으로 로그인하는 중 오류가 발생했습니다.");
      console.error("Google 로그인 오류:", error);
    }
  };

  return (
    <div className="signup-container">
      <h1>Sign Up</h1>
      <form onSubmit={handleSignUp}>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Email" 
          className="signup-input"
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
          className="signup-input"
        />
        <input 
          type="password" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          placeholder="Confirm Password" 
          className="signup-input"
        />
        <button type="submit" className="signup-button">Submit</button>
        {error && <p className="error-message">{error}</p>}
      </form>
      
      {/* Google 계정으로 로그인 버튼 */}
      <button onClick={handleGoogleSignUp} className="google-button">
        Google 계정을 사용하기
      </button>
    </div>
  );
}

export default SignUp;
