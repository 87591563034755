// Login.js

import React, { useState, useEffect } from "react";
import { auth } from "./firebase"; // Firebase 인증 및 Google 제공자 가져오기
import { signInWithEmailAndPassword, getRedirectResult, fetchSignInMethodsForEmail, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebase"; // Firestore 데이터베이스 가져오기
// import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import './Login.css';

function Login() {
  const [loginID, setLoginID] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");

    try {
      // 기존 세션이 있는지 확인
      const signInMethods = await fetchSignInMethodsForEmail(auth, loginID);

      if (signInMethods.length > 0) {
        const confirmLogout = window.confirm("기존 세션이 있습니다. 이 세션을 강제로 종료하고 로그인을 진행할까요?");
        if (confirmLogout) {
          await handleForceLogout(); // 기존 세션 강제 종료
        } else {
          return; // 사용자가 취소를 선택한 경우 함수 종료
        }
      }

      // Firebase Auth를 사용하여 로그인
      await signInWithEmailAndPassword(auth, loginID, password);

      // 로그인 성공 시 쿠키에 저장 (.env 파일의 만료 시간)
      const expiryTime = process.env.REACT_APP_COOKIE_EXPIRY || "1h";
      localStorage.setItem('sessionID', loginID);
      localStorage.setItem('expiryTime', expiryTime);

      navigate('/chatroom'); // 로그인 성공 시 채팅룸으로 이동
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setError("사용자를 찾을 수 없습니다.");
      } else if (error.code === 'auth/wrong-password') {
        setError("잘못된 비밀번호입니다.");
      } else {
        setError("로그인에 실패했습니다. 다시 시도해주세요.");
      }
      console.error("로그인 오류:", error);
    }
  };

  const handleForceLogout = async () => {
    try {
      await signOut(auth); // 모든 세션 로그아웃
      console.info("기존 세션이 종료되었습니다.");
    } catch (error) {
      console.error("로그아웃 중 오류 발생:", error);
    }
  };

  // 로그인 후 결과 처리
  useEffect(() => { 
    const fetchRedirectResult = async () => {
      console.log("fetchRedirectResult");
      try {
        // Firebase redirects the user to the authentication provider's login page (like Google or Facebook). 
        // After the user completes the sign-in process, they are redirected back to your application.
        console.log("start getRedirectResult(auth)");
        const userCredential = await getRedirectResult(auth);
        console.log("userCredential: ", userCredential);
        if (userCredential) {
          // 로그인 성공 시 sessionID 생성
          const sessionID = Math.random().toString(36).substring(2); // 임의의 sessionID 생성
          const user = userCredential.user;

          // Firestore에 sessionID 저장
          const userRef = doc(db, "users", user.uid);
          await setDoc(userRef, {
            currentSession: sessionID,
            sessionTimestamp: new Date(),
          }, { merge: true });

          // 로컬 스토리지에 sessionID 저장
          localStorage.setItem("sessionID", sessionID);

          console.log("로그인 성공:", user);
          navigate('/chatroom'); // 채팅룸으로 이동
        }
      } catch (error) {
        console.error("리디렉션 로그인 오류:", error);
        setError("로그인 처리 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
    };

    fetchRedirectResult();
  }, [navigate]);

  // const handleSignUp = () => {
  //   navigate('/signup'); // 회원가입 페이지로 이동
  // };

  

  return (
    <div className="login-container">
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <input 
          type="text" 
          value={loginID} 
          onChange={(e) => setLoginID(e.target.value)} 
          placeholder="Login ID" 
          className="login-input"
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
          className="login-input"
        />
        <br />
        <button type="submit" className="login-button">Login</button>
        {error && <p className="error-message">{error}</p>}
      </form> 
    </div>
  );
}

export default Login;
