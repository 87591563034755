import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ChatRoom from "./pages/ChatRoom";
import About from "./pages/About"; 
import Logout from "./pages/Logout"; 
import { logoutUser } from "./pages/Logout"; // 로그아웃 함수 가져오기

import SignOut from "./pages/SignOut"; 
import './App.css';

function App() {
  useEffect(() => {
    const handleBeforeUnload = () => {
      // 브라우저가 닫히거나 새로 고침될 때 로그아웃
      logoutUser();
    };

    // 브라우저가 닫힐 때 이벤트 핸들러 등록
    window.addEventListener("beforeunload", handleBeforeUnload);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  console.log("I am here in App.js before useState");

  const [userStatus, setUserStatus] = useState(null);

  console.log("I am here in App.js after useState");

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          {/* 나비 애니메이션 요소 추가 */}
          {/* <img src="butterfly.png" alt="Butterfly" className="butterfly" /> */}

          {/* 네비게이션 링크들 */}
          <NavLink to="/" className={({ isActive }) => (isActive ? "active" : "")}> Home </NavLink>
          <NavLink to="/about" className={({ isActive }) => (isActive ? "active" : "")}> About </NavLink>
          

          {/* 로그인 상태 표시 */}
          {/* {userStatus ? (
            <div className="user-status">{userStatus}</div>
          ) : (
            <NavLink to="/login" className={({ isActive }) => (isActive ? "active" : "")}> Login </NavLink>
          )} */}

          <NavLink to="/logout" className={({ isActive }) => (isActive ? "active" : "")}> Logout </NavLink>
          <NavLink to="/signup" className={({ isActive }) => (isActive ? "active" : "")}> Sign Up </NavLink>
          <NavLink to="/signout" className={({ isActive }) => (isActive ? "active" : "")}> Sign Out </NavLink>
        </nav>

        <div className="content">
          <Routes>
            <Route path="/" element={<Home setUserStatus={setUserStatus} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/chatroom" element={<ChatRoom />} />
            <Route path="/about" element={<About />} /> 
            <Route path="/logout" element={<Logout setUserStatus={setUserStatus} />} /> 
            <Route path="/signup" element={<SignUp setUserStatus={setUserStatus} />} /> 
            <Route path="/signout" element={<SignOut setUserStatus={setUserStatus} />} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
