// signOut.js

import React, { useState } from 'react';
import { getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase'; // Firestore 데이터베이스 가져오기
import { useNavigate } from 'react-router-dom';

function SignOut() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (!user) {
      setError('사용자가 로그인되어 있지 않습니다.');
      return;
    }

    const credential = EmailAuthProvider.credential(user.email, password);

    try {
      // 사용자 재인증
      await reauthenticateWithCredential(user, credential);

      // Firestore에서 사용자 데이터 삭제
      const userDocRef = doc(db, 'users', user.uid);
      await deleteDoc(userDocRef);

      // Firebase Authentication에서 사용자 삭제
      await deleteUser(user);

      alert('회원탈퇴가 성공적으로 완료되었습니다.');
      navigate('/'); // 홈 또는 다른 페이지로 이동
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('비밀번호가 잘못되었습니다.');
      } else {
        setError('회원탈퇴 중 오류가 발생했습니다. 다시 시도해주세요.');
      }
      console.error('회원탈퇴 오류:', error);
    }
  };

  return (
    <div className="signout-container">
      <h2>회원탈퇴</h2>
      <input
        type="password"
        placeholder="비밀번호를 입력하세요"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="password-input"
      />
      <button onClick={handleDeleteAccount} className="delete-button">
        회원탈퇴
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default SignOut;
